import { makeStyles } from '@fluentui/react-components';
import { DocumentTextClockRegular, ErrorCircleRegular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Body, Button, Divider, FormattedCurrency } from '@zastrpay/components';
import { MessagePage } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { ActiveKycRequest } from './ActiveKycRequest';
import { useKycRequest } from './KycRequestProvider';
import { KycRequest, SuggestedAmount } from './models';

type OverviewStep = {
    type: 'overview';
};

type DocumentReviewStep = {
    type: 'document-review';
};

type NextKycRequestStep = {
    type: 'next-kyc-request';
    kycRequest: KycRequest;
};

type Step = OverviewStep | DocumentReviewStep | NextKycRequestStep;

export type RequiredKycRequestProps = {
    kycRequestList: KycRequest[];
    suggestedAmount?: SuggestedAmount;
    merchantName?: string;
    onCancel: () => void;
};

export const RequiredKycRequest: React.FC<RequiredKycRequestProps> = ({ kycRequestList, suggestedAmount, merchantName, onCancel }) => {
    const classes = useStyles();
    const { t } = useTranslation('kyc-requests');

    const { setRequiredRequestListInProgress, getRequiredRequestListProgressState } = useKycRequest();

    const [step, setStep] = useState<Step>();

    useEffect(() => {
        if (kycRequestList.length === 0) {
            return;
        }

        const loadedKycRequestList = kycRequestList.map(({ id }) => id);
        if (kycRequestList.every(({ state }) => state === 'PendingManualReview')) {
            setStep({ type: 'document-review' });
        } else if (getRequiredRequestListProgressState() !== 'in-progress') {
            setRequiredRequestListInProgress(loadedKycRequestList);
            setStep({ type: 'overview' });
        } else {
            setStep({ type: 'next-kyc-request', kycRequest: kycRequestList[0] });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleProvideInformation = () => {
        setStep({ type: 'next-kyc-request', kycRequest: kycRequestList[0] });
    };

    switch (step?.type) {
        case 'overview':
            return (
                <MessagePage
                    icon={<ErrorCircleRegular className={classes.overdue} />}
                    title={t('requiredKycRequest.overview.title')}
                    message={<Body>{t('requiredKycRequest.overview.mainDescription')}</Body>}
                    action={t('requiredKycRequest.overview.mainAction')}
                    onAction={handleProvideInformation}
                >
                    {suggestedAmount && (
                        <>
                            <Divider>{t('requiredKycRequest.overview.divider')}</Divider>
                            <Body>
                                <Trans
                                    t={t}
                                    i18nKey="requiredKycRequest.overview.secondaryDescription"
                                    components={{
                                        amount: (
                                            <FormattedCurrency
                                                currency={suggestedAmount.currency}
                                                value={suggestedAmount.amount}
                                                format="sign"
                                            />
                                        ),
                                    }}
                                />
                            </Body>
                            <Button size="large" appearance="outline" onClick={onCancel}>
                                <Trans
                                    t={t}
                                    i18nKey="requiredKycRequest.overview.secondaryAction"
                                    components={{
                                        amount: (
                                            <FormattedCurrency
                                                currency={suggestedAmount.currency}
                                                value={suggestedAmount.amount}
                                                format="sign"
                                            />
                                        ),
                                    }}
                                    values={merchantName ? { context: 'merchant', merchant: merchantName } : {}}
                                />
                            </Button>
                        </>
                    )}
                </MessagePage>
            );
        case 'document-review':
            const merchantTranslationContext = merchantName ? { merchant: merchantName } : { context: 'GenericMerchant' };

            return (
                <MessagePage
                    icon={<DocumentTextClockRegular />}
                    title={t('requiredKycRequest.documentReview.title')}
                    message={
                        <>
                            <Body block>{t('requiredKycRequest.documentReview.message')}</Body>
                            {suggestedAmount && (
                                <div className={classes.text}>
                                    <Trans
                                        t={t}
                                        i18nKey="requiredKycRequest.documentReview.secondaryMessage"
                                        components={{
                                            amount: (
                                                <FormattedCurrency
                                                    currency={suggestedAmount.currency}
                                                    value={suggestedAmount.amount}
                                                    format="sign"
                                                />
                                            ),
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    }
                    action={
                        suggestedAmount ? (
                            <Trans
                                t={t}
                                i18nKey="requiredKycRequest.documentReview.action"
                                components={{
                                    amount: (
                                        <FormattedCurrency
                                            currency={suggestedAmount.currency}
                                            value={suggestedAmount.amount}
                                            format="sign"
                                        />
                                    ),
                                }}
                                values={merchantTranslationContext}
                            />
                        ) : (
                            t('requiredKycRequest.documentReview.backAction', merchantTranslationContext)
                        )
                    }
                    onAction={onCancel}
                />
            );
        case 'next-kyc-request':
            return <ActiveKycRequest mode="page" kycRequest={step.kycRequest} skipAlertDisplay={true} skippable={false} />;
        case undefined:
            return null;
    }
};

const useStyles = makeStyles({
    overdue: {
        color: tokens.customPaletteRed,
    },
    list: {
        textAlign: 'left',
        marginBottom: tokens.spacingVerticalNone,
    },
    text: {
        marginTop: tokens.spacingVerticalL,
    },
});
