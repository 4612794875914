import { useEffect, useState } from 'react';

import { useAuth } from '@zastrpay/auth';
import { useErrorBoundary } from '@zastrpay/components';
import { LimitDirection, useTransactionLimits } from '@zastrpay/kyc-limits';
import { isRequiredKycRequest, KycRequest, SuggestedAmount, useKycRequest } from '@zastrpay/kyc-requests';

import { useApp } from '../AppProvider';
import { TransactionData } from '../auth/models';
import { featureToggles, limitsCheckAllowedLimitIds } from '../config';

export const getLimitDirection = ({ type, direction }: TransactionData): LimitDirection => {
    if (type) {
        switch (type) {
            case 'PassthroughDeposit':
            case 'CustomerToMerchantTransfer':
            case 'CustomerToMerchantPassthrough':
                return 'Credit';
            case 'PassthroughWithdrawal':
            case 'MerchantToCustomerTransfer':
            case 'MerchantToCustomerPassthrough':
                return 'Debit';
        }
    }

    if (direction) {
        switch (direction) {
            case 'CustomerToMerchant':
                return 'Credit';
            case 'MerchantToCustomer':
                return 'Debit';
        }
    }

    throw new Error('Transaction type or direction is required to determine limit direction');
};

type TransactionKycParams = {
    useCache?: boolean;
};

export const useTransactionKyc = ({ useCache = false }: TransactionKycParams) => {
    const { customerId } = useAuth();
    const { merchant, redirectSession } = useApp();
    const { trackError } = useErrorBoundary();

    const { loadRequests } = useKycRequest();
    const { checkLimits } = useTransactionLimits();

    const [isChecksCompleted, setIsChecksCompleted] = useState(false);
    const [limitRequests, setLimitRequests] = useState<KycRequest[]>([]);
    const [suggestedAmount, setSuggestedAmount] = useState<SuggestedAmount | undefined>();

    const RETRY_TIMEOUT = 200;
    const MAX_RETRY_ATTEMPTS = 3;
    const [retryCount, setRetryCount] = useState<number>(0);

    const canLoad = customerId && merchant && redirectSession;

    useEffect(() => {
        // TODO: remove feature toggle after transaction monitors testing
        if (!featureToggles.limitsCheckEnabled) {
            setIsChecksCompleted(true);
            return;
        }

        if (!canLoad) {
            return;
        }

        if (redirectSession.type === 'NewTransactionIntent') {
            const kycPromise = loadRequests();
            const limitsPromise = checkLimits(
                {
                    customerId,
                    sessionId: redirectSession.id,
                    amount: redirectSession.transactionData.amount,
                    currency: redirectSession.transactionData.currency,
                    limitDirection: getLimitDirection(redirectSession.transactionData),
                    merchantCategoryCode: merchant.categoryCode,
                },
                { useCache, allowedLimitIds: limitsCheckAllowedLimitIds },
            );

            Promise.all([kycPromise, limitsPromise]).then(
                ([{ pendingRequests, conditionalRequests }, { exceededLimits, availableBalance }]) => {
                    const limitRequests = [];
                    for (const limit of exceededLimits) {
                        const matchingRequests = conditionalRequests.filter((request) =>
                            request.referenceEntities.some((ref) => ref.id === limit.limitId),
                        );
                        if (matchingRequests.length === 0) {
                            if (retryCount < MAX_RETRY_ATTEMPTS) {
                                // with exponential delay 200, 400, 800 ms
                                setTimeout(() => setRetryCount((count) => count + 1), 2 ** retryCount * RETRY_TIMEOUT);
                                return;
                            } else {
                                const error = new Error(`KycRequest not found for limitId: ${limit.limitId} after ${retryCount} retries`);
                                trackError(error);
                                throw error;
                            }
                        }
                        limitRequests.push(...matchingRequests);
                    }

                    const onlyRequiredLimits = pendingRequests.every((r) => !isRequiredKycRequest(r)) && limitRequests.length > 0;
                    const suggestedAmount =
                        onlyRequiredLimits && availableBalance
                            ? { amount: availableBalance, currency: redirectSession.transactionData.currency }
                            : undefined;

                    setSuggestedAmount(suggestedAmount);
                    setLimitRequests(limitRequests);
                    setIsChecksCompleted(true);
                },
            );
        } else {
            // for existing transaction intent, checks are already completed
            setIsChecksCompleted(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retryCount]);

    return { isChecksCompleted, limitRequests, suggestedAmount };
};
