import { bool, required } from '@zastrpay/common';

export const otpResendWaitSeconds = 30;

export const endpoints = {
    custAuthCmdh: required(import.meta.env.VITE_CUST_AUTH_SVC_CMDH_URL, 'VITE_CUST_AUTH_SVC_CMDH_URL'),
    custAuthQryh: required(import.meta.env.VITE_CUST_AUTH_SVC_QRYH_URL, 'VITE_CUST_AUTH_SVC_QRYH_URL'),
    custCmdh: required(import.meta.env.VITE_CUST_SVC_CMDH_URL, 'VITE_CUST_SVC_CMDH_URL'),
    custQryh: required(import.meta.env.VITE_CUST_SVC_QRYH_URL, 'VITE_CUST_SVC_QRYH_URL'),
    trxCmdh: required(import.meta.env.VITE_TRX_SVC_CMDH_URL, 'VITE_TRX_SVC_CMDH_URL'),
    trxQryh: required(import.meta.env.VITE_TRX_SVC_QRYH_URL, 'VITE_TRX_SVC_QRYH_URL'),
    distrQryh: required(import.meta.env.VITE_DISTR_SVC_QRYH_URL, 'VITE_DISTR_SVC_QRYH_URL'),
    merchQryh: required(import.meta.env.VITE_MERCH_SVC_QRYH_URL, 'VITE_MERCH_SVC_QRYH_URL'),
    locQryh: required(import.meta.env.VITE_LOC_SVC_QRYH_URL, 'VITE_LOC_SVC_QRYH_URL'),
};

export const eventHubs = {
    trxEvh: required(import.meta.env.VITE_TRX_SVC_EVH_URL, 'VITE_TRX_SVC_EVH_URL'),
    custExtEvp: required(import.meta.env.VITE_CUST_SVC_EXTEVP_URL, 'VITE_CUST_SVC_EXTEVP_URL'),
};

export const featureToggles = {
    limitsCheckEnabled: bool(import.meta.env.VITE_LIMITS_CHECK_ENABLED),
};

export const applicationInsights = {
    connectionString: required(import.meta.env.VITE_APPINSIGHTS_CONNECTION_STRING, 'VITE_APPINSIGHTS_CONNECTION_STRING'),
    enabled: bool(import.meta.env.VITE_APPINSIGHTS_ENABLED),
};

export const requestConfigs = {
    retries: Number(required(import.meta.env.VITE_REQUEST_RETRIES, 'VITE_REQUEST_RETRIES')),
    delay: Number(required(import.meta.env.VITE_REQUEST_RETRY_DELAY, 'VITE_REQUEST_RETRY_DELAY')),
};

export const pageWidth = 480;
export const headerHeight = 48;

export const idRequirementThreshold = Number(import.meta.env.VITE_DEFAULT_ID_REQUIREMENT_THRESHOLD ?? 2000);
export const MOCK_AUTHENTICATION_HEADERS = import.meta.env.VITE_MOCK_AUTHENTICATION_HEADERS === 'true';

export const questionnairePreselectedAnswerOptions: Record<string, string[]> = {
    '7a1346da-90e9-4b4d-b304-b7e8549fc39d': required<string>(
        import.meta.env['VITE_QUESTIONNAIRE_7a1346da_90e9_4b4d_b304_b7e8549fc39d_PRESELECTED_ANSWER_OPTIONS'],
        'VITE_QUESTIONNAIRE_7a1346da_90e9_4b4d_b304_b7e8549fc39d_PRESELECTED_ANSWER_OPTIONS',
    ).split(','),

    '03b0b816-fcc5-409e-92e4-3d130f57eabc': required<string>(
        import.meta.env['VITE_QUESTIONNAIRE_03b0b816_fcc5_409e_92e4_3d130f57eabc_PRESELECTED_ANSWER_OPTIONS'],
        'VITE_QUESTIONNAIRE_03b0b816_fcc5_409e_92e4_3d130f57eabc_PRESELECTED_ANSWER_OPTIONS',
    ).split(','),
};

export const limitsCheckAllowedLimitIds = required<string>(
    import.meta.env.VITE_LIMITS_CHECK_ALLOWED_LIMIT_IDS,
    'VITE_LIMITS_CHECK_ALLOWED_LIMIT_IDS',
).split(',');
