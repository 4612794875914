import { makeStyles } from '@fluentui/react-components';
import { ChevronDown20Regular } from '@fluentui/react-icons';
import { ChangeEvent, useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';

import { ALLOWED_PHONE_COUNTRIES } from '@zastrpay/common';
import { useCountries } from '@zastrpay/hooks';
import { tokens } from '@zastrpay/theme';

import { Input } from './Input';

// German mobile numbers need to start with (0)15X or (0)16X or (0)17X
// and must have at least 10 digits (without country code).
export const PHONE_REGEX = /^\+((491[5-7][0-9])\d{7,15}|(?!49)\d{7,50})$/;
export const PHONE_DEFAULT_PREFIX = 'DE';

export const PHONE_TOP_PREFIXES = ['DE', 'AT', 'TR'] as const;

export type PhoneProps = {
    defaultValue?: string;
    onChange?: (phoneNumber?: string) => void;
};

export const Phone: React.FC<PhoneProps> = ({ onChange, ...props }) => {
    const { t } = useTranslation('components');
    const classes = useStyles();

    const { defaultCountry, countries, topCountries, otherCountries } = useCountries({
        filter: ALLOWED_PHONE_COUNTRIES,
        top: PHONE_TOP_PREFIXES,
        default: PHONE_DEFAULT_PREFIX,
        sort: 'name',
    });

    const [phoneCountry, setPhonePrefix] = useState(defaultCountry);
    const [phoneNumber, setPhoneNumber] = useState<string>('');

    useEffect(() => {
        if (phoneNumber) {
            onChange?.(phoneCountry.callingCode + phoneNumber.replace(/[^0-9]/g, ''));
        } else {
            onChange?.();
        }
    }, [phoneCountry, phoneNumber, onChange]);

    useEffect(() => {
        if (props.defaultValue) {
            updateNumber(props.defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changePrefix = (event: ChangeEvent<HTMLSelectElement>) => {
        const phonePrefix = countries.find((current) => current.countryCode === event.target.value) ?? defaultCountry;

        if (phonePrefix) {
            setPhonePrefix(phonePrefix);
        }
    };

    const updateNumber = (phone: string) => {
        // if value was pasted or autocompleted with a country code find the correct prefix and remove it form the phone number
        if (phone.startsWith('+')) {
            // find the longest matching prefix
            const filteredPrefix = countries.filter((p) => phone.startsWith(p.callingCode));

            if (filteredPrefix.length) {
                const country = filteredPrefix.reduce((a, b) => (a.callingCode.length <= b.callingCode.length ? b : a));

                if (country) {
                    phone = phone.replace(country.callingCode, '');
                    setPhonePrefix(country);
                }
            }
        }

        phone = phone
            .replace(/[^0-9\\/\-() ]/g, '') // remove all invalid characters (for example letters, etc)
            .replace(/^[^1-9]?0/, ''); // remove the first occurrence  of a 0

        setPhoneNumber(phone);
    };

    const changeNumber = (event: ChangeEvent<HTMLInputElement>) => {
        const phone = event.target.value.trimStart();

        updateNumber(phone);
    };

    return (
        <Input
            className={classes.phoneInput}
            placeholder="123 4567890"
            aria-label={t('phone.national')}
            contentBefore={{
                className: classes.prefixWrapper,
                children: (
                    <>
                        <select
                            value={phoneCountry.countryCode}
                            className={classes.prefixInput}
                            onChange={changePrefix}
                            autoComplete="tel-country-code"
                            aria-label={t('phone.countryCode')}
                        >
                            {topCountries.map((current) => (
                                <option value={current.countryCode} key={current.countryCode}>
                                    {current.name} ({current.callingCode})
                                </option>
                            ))}
                            {topCountries.length > 1 && otherCountries.length > 1 && <option disabled />}
                            {otherCountries.map((current) => (
                                <option value={current.countryCode} key={current.countryCode}>
                                    {current.name} ({current.callingCode})
                                </option>
                            ))}
                        </select>
                        <div className={classes.prefix}>
                            <div className={classes.flagWrapper}>
                                <ReactCountryFlag countryCode={phoneCountry.countryCode} svg className={classes.flag} role="none" />
                            </div>
                            <ChevronDown20Regular />
                            <span aria-hidden className={classes.prefixValue}>
                                {phoneCountry.callingCode}
                            </span>
                        </div>
                    </>
                ),
            }}
            value={phoneNumber}
            type="tel"
            name="phone"
            autoComplete="tel"
            size="large"
            onChange={changeNumber}
        />
    );
};

const useStyles = makeStyles({
    phoneInput: {
        paddingLeft: 0,
        alignItems: 'stretch',
        alignSelf: 'stretch',
        ':focus-within .fui-Input__contentBefore': {
            borderRightColor: tokens.colorNeutralStroke1Selected,
        },
    },
    prefixWrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        borderRight: `1px solid ${tokens.colorNeutralStroke1}`,
        padding: `0 ${tokens.spacingHorizontalM}`,
        borderRadius: `${`calc(${tokens.borderRadiusMedium} + 1px)`} 0 0 ${`calc(${tokens.borderRadiusMedium} + 1px)`}`,

        ':has(select:focus-visible)': {
            background: tokens.colorNeutralBackground4,
        },
    },
    prefixInput: {
        maxWidth: '100%',
        opacity: 0,
        position: 'absolute',
        left: 0,
        right: 0,
    },
    prefix: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
    },
    prefixValue: {
        fontVariant: 'lining-nums',
    },
    flagWrapper: {
        borderRadius: '100%',
        overflow: 'hidden',
        width: '18px',
        height: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    flag: {
        height: '100% !important',
        width: 'auto !important',
    },
});
