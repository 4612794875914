import { makeStyles } from '@fluentui/react-components';
import { useCallback, useState } from 'react';

import { Body, Button, Dialog } from '@zastrpay/components';
import { CloseButton } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { useApp } from '../AppProvider';
import { useSessionNavigation } from '../layout/SessionNavigationProvider';

export type CancelSessionButtonProps = {
    icon: React.ReactElement | false;
    title: string | React.ReactElement;
    subTitle?: string | React.ReactElement;
    confirm: string;
    close: string;
};

export const CancelSessionButton: React.FC<CancelSessionButtonProps> = ({ icon, title, subTitle, confirm, close }) => {
    const { codeFinalized } = useApp();
    const { cancel, success } = useSessionNavigation();
    const classes = useStyles();

    const [cancelConfirmation, setCancelConfirmation] = useState(false);

    const cancelConfirmed = (confirmed?: boolean) => {
        setCancelConfirmation(false);

        if (confirmed) {
            cancel();
        }
    };

    const cancelAction = useCallback(() => {
        if (codeFinalized) {
            success();
        } else {
            setCancelConfirmation(true);
        }
    }, [codeFinalized, success]);

    return (
        <>
            <CloseButton onClick={cancelAction} />

            <Dialog
                open={cancelConfirmation}
                onOpenChange={() => cancelConfirmed(false)}
                align="bottom"
                dismissible={false}
                icon={icon}
                title={title}
                actions={
                    <>
                        <Button className={classes.button} onClick={() => cancelConfirmed(true)} size="large" appearance="outline">
                            {confirm}
                        </Button>
                        <Button className={classes.button} onClick={() => cancelConfirmed(false)} size="large" appearance="primary">
                            {close}
                        </Button>
                    </>
                }
            >
                {subTitle && <Body block>{subTitle}</Body>}
            </Dialog>
        </>
    );
};

const useStyles = makeStyles({
    button: {
        flex: `1 0 calc(50% - ${tokens.spacingHorizontalS})`,
        minWidth: 'fit-content',
    },
});
