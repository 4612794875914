import { makeStyles } from '@fluentui/react-components';
import { forwardRef, Fragment, useCallback, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { tokens } from '@zastrpay/theme';

import { DigitInput } from './DigitInput';

const useStyles = makeStyles({
    inputGroup: {
        display: 'flex',
        gap: '8px',
    },
    hyphen: {
        alignSelf: 'center',
        fontSize: tokens.fontSizeBase400,
        fontWeight: tokens.fontWeightSemibold,
        paddingBottom: tokens.spacingVerticalXS,
    },
});

export type PinElement = {
    reset: () => void;
    focus: () => void;
};

export type PinProps = {
    autoFill?: boolean;
    autoFocus?: boolean;
    length?: number;
    disabled?: boolean;
    hyphenated?: boolean;
    onInput?: (pin?: string) => void;
};

export const Pin = forwardRef<PinElement, PinProps>(({ onInput, length = 4, ...props }, ref) => {
    const { t } = useTranslation('components');
    const classes = useStyles();

    const [activeInput, setActiveInput] = useState(props.autoFocus ? 0 : -1);
    const [values, setValues] = useState(Array<string>(length).fill(''));

    useImperativeHandle(ref, () => ({
        reset: () => {
            updateValues(
                values.map(() => ''),
                false,
            );
            setActiveInput(-1);
        },
        focus: () => {
            focusInput(0);
        },
    }));

    const updateValues = useCallback(
        (values: string[], triggerInputEvent = true) => {
            setValues(values);

            if (triggerInputEvent) {
                const value = values.join('');
                if (value.length === length) {
                    onInput?.(value);
                    setActiveInput(-1);
                } else {
                    onInput?.();
                }
            }
        },
        [length, setActiveInput, onInput],
    );

    // Change OTP value at focussing input
    const changeCodeAtFocus = useCallback(
        (str: string, triggerInputEvent = true) => {
            const updatedValues = [...values];
            updatedValues[activeInput] = str[0] ?? '';
            updateValues(updatedValues, triggerInputEvent);
        },
        [activeInput, updateValues, values],
    );

    // Focus `inputIndex` input
    const focusInput = useCallback(
        (inputIndex: number) => {
            const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
            setActiveInput(selectedIndex);
        },
        [length],
    );

    const focusPrevInput = useCallback(() => {
        focusInput(activeInput - 1);
    }, [activeInput, focusInput]);

    const focusNextInput = useCallback(() => {
        focusInput(activeInput + 1);
    }, [activeInput, focusInput]);

    // Handle onFocus input
    const handleOnFocus = useCallback(
        (index: number) => () => {
            const enteredDigits = values.join('').length;
            focusInput(Math.min(index, enteredDigits));
        },
        [focusInput, values],
    );

    // Handle onChange value for each input
    const handleOnInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const val = e.currentTarget.value;

            if (val && val.match(/^[0-9]+$/)) {
                if (val.length === 1) {
                    changeCodeAtFocus(val);
                    focusNextInput();
                } else if (val.length === length) {
                    // Android autofill currently forces all digits at once
                    // so handle this the same way as a paste
                    setActiveInput(-1);
                    updateValues(val.split(''));
                }
                return;
            }

            e.preventDefault();
        },
        [changeCodeAtFocus, focusNextInput, length, updateValues],
    );

    // Handle onBlur input
    const handleOnBlur = useCallback(() => {
        setActiveInput(-1);
    }, []);

    // Handle onKeyDown input
    const handleOnKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            switch (e.key) {
                case 'Unidentified':
                    break;
                case 'Backspace':
                case 'Delete': {
                    e.preventDefault();
                    changeCodeAtFocus('');
                    focusPrevInput();
                    break;
                }
                case 'ArrowLeft': {
                    e.preventDefault();
                    focusPrevInput();
                    break;
                }
                case 'ArrowRight': {
                    e.preventDefault();
                    focusNextInput();
                    break;
                }
                case 'Tab':
                    break;
                default: {
                    // allow shortcuts like ctrl+v, cmd+c, etc
                    if (e.metaKey || e.ctrlKey) {
                        break;
                    }

                    if (e.key.match(/^[0-9]$/)) {
                        // if digit was input reset current field value
                        changeCodeAtFocus('', false);
                    } else {
                        e.preventDefault();
                    }
                    break;
                }
            }
        },
        [changeCodeAtFocus, focusNextInput, focusPrevInput],
    );

    const handleOnPaste = useCallback(
        (event: React.ClipboardEvent<HTMLInputElement>) => {
            event.preventDefault();
            const pastedValue = event.clipboardData?.getData('text/plain').replace(/\D/g, '').trim().slice(0, length);

            if (pastedValue?.match(/^[0-9]+$/)) {
                const pastedValues = pastedValue.split('');
                setActiveInput(-1);
                updateValues(pastedValues);
            }
        },
        [length, updateValues],
    );

    // SMS format does not work if website is embedded within a WebView on iOS
    // useEffect(() => {
    //     if (props.autoFill && 'OTPCredential' in window) {
    //         const ac = new AbortController();
    //         // Invoke the Web OTP API
    //         navigator.credentials
    //             .get({
    //                 otp: { transport: ['sms'] },
    //                 signal: ac.signal,
    //             })
    //             .then((otp) => {
    //                 if (otp?.code?.length === length) {
    //                     updateValues(otp.code.split(''));
    //                 }
    //             })
    //             .catch((err) => {
    //                 if (err !== 'Effect cleanup') {
    //                     ac.abort();
    //                     console.error(err);
    //                 }
    //             });

    //         return () => ac.abort('Effect cleanup');
    //     }
    // }, [length, props.autoFill, updateValues]);

    return (
        <div className={classes.inputGroup}>
            {Array(length)
                .fill('')
                .map((_, index) => (
                    <Fragment key={index}>
                        <DigitInput
                            autoComplete={props.autoFill && index === 0 ? 'one-time-code' : 'off'}
                            // should ideally be 1, but current android autofill does not play well with it
                            maxLength={length}
                            focus={activeInput === index}
                            value={values && values[index]}
                            onFocus={handleOnFocus(index)}
                            onInput={handleOnInput}
                            onKeyDown={handleOnKeyDown}
                            onBlur={handleOnBlur}
                            onPaste={handleOnPaste}
                            disabled={props.disabled}
                            // only allow at max to focus next input if current input is filled
                            // so we can break out of focus loop if we are at the end
                            tabIndex={index === 0 || values[index - 1] ? 0 : -1}
                            aria-label={t('pin.label', { number: index + 1 })}
                        />
                        {
                            // adds hyphen after the middle digit if enabled
                            props.hyphenated && index === Math.floor(length / 2) - 1 && <span className={classes.hyphen}>—</span>
                        }
                    </Fragment>
                ))}
        </div>
    );
});
