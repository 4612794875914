import { makeStyles } from '@fluentui/react-components';
import { BuildingBankRegular, ClipboardTextEditRegular, MoneyHandRegular, ShieldKeyholeRegular } from '@fluentui/react-icons';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { PrivacyPolicyDialog, TermsAndConditionsDialog, trackClick, trackPage, TrustedBeneficiaryDialog } from '@zastrpay/analytics';
import { Body, Link } from '@zastrpay/components';
import { useCountries, useRequestLoader } from '@zastrpay/hooks';
import { BackButton, Page, useLayout } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { REG_ASK_SELF_PEP } from '../config';
import { Merchant } from '../models';
import { AdditionalDataQuestion } from './AdditionalDataQuestion';
import { FinancialTiesDialog } from './FinancialTiesDialog';
import { PepDialog } from './PepDialog';

type AdditionalDataFormForm = {
    pep: boolean;
    whitelisted: boolean;
    linksToHighRiskCountries: boolean | undefined;
};

export type AdditionalData = {
    selfDeclaredPep?: boolean;
    acceptedTacVersion: string;
    scaWhitelistedMerchantIds?: string[];
    selfDeclaredLinksToHighRiskCountries?: boolean;
};

export type AdditionalDataFormProps = {
    merchant?: Merchant;
    highRiskCountries: string[];
    onComplete?: (form: AdditionalData) => void;
    onBack?: () => void;
};

type PepStep = {
    type: 'pep';
};

type WhitelistingStep = {
    type: 'trustedBeneficiary';
    merchant: Merchant;
};

type HighRiskCountriesStep = {
    type: 'highRiskCountries';
    highRiskCountries: string[];
};

type TacStep = {
    type: 'tac';
};

type Step = PepStep | WhitelistingStep | HighRiskCountriesStep | TacStep;

export const AdditionalDataForm: React.FC<AdditionalDataFormProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('registration');

    const { countries } = useCountries();

    const [form, setForm] = useState<Partial<AdditionalDataFormForm>>({});
    const [showTermsDialog, setShowTermsDialog] = useState(false);
    const [showPrivacyDialog, setShowPrivacyDialog] = useState(false);

    const [step, setStep] = useState<Step>(
        REG_ASK_SELF_PEP ? { type: 'pep' } : props.merchant ? { type: 'trustedBeneficiary', merchant: props.merchant } : { type: 'tac' },
    );
    const [showInfoDialog, setShowInfoDialog] = useState<'info' | 'confirmation' | false>(false);

    const { setHeaderSlot } = useLayout();

    const loading = useRequestLoader();

    useEffect(() => {
        const back = () => {
            if (step.type === 'tac' && REG_ASK_SELF_PEP) {
                setStep(
                    props.highRiskCountries.length > 0
                        ? { type: 'highRiskCountries', highRiskCountries: props.highRiskCountries }
                        : props.merchant
                          ? { type: 'trustedBeneficiary', merchant: props.merchant }
                          : { type: 'pep' },
                );
            } else if (step.type === 'tac' && !REG_ASK_SELF_PEP) {
                props.merchant ? setStep({ type: 'trustedBeneficiary', merchant: props.merchant }) : props.onBack?.();
            } else if (step.type === 'highRiskCountries') {
                setStep(props.merchant ? { type: 'trustedBeneficiary', merchant: props.merchant } : { type: 'pep' });
            } else if (step.type === 'trustedBeneficiary' && REG_ASK_SELF_PEP) {
                setStep({ type: 'pep' });
            } else if (step.type === 'trustedBeneficiary' && !REG_ASK_SELF_PEP) {
                props.onBack?.();
            } else {
                props.onBack?.();
            }
        };

        setHeaderSlot('left', <BackButton onClick={back} />);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step.type]);

    const next = useCallback(() => {
        if (step.type === 'pep') {
            setStep(
                props.merchant
                    ? { type: 'trustedBeneficiary', merchant: props.merchant }
                    : props.highRiskCountries.length > 0
                      ? { type: 'highRiskCountries', highRiskCountries: props.highRiskCountries }
                      : { type: 'tac' },
            );
        } else if (step.type === 'trustedBeneficiary') {
            setStep(
                props.highRiskCountries.length > 0
                    ? { type: 'highRiskCountries', highRiskCountries: props.highRiskCountries }
                    : { type: 'tac' },
            );
        } else if (step.type === 'highRiskCountries') {
            setStep({ type: 'tac' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step.type]);

    useEffect(() => {
        trackPage('additional_information');
    }, []);

    const submit = () => {
        const data: AdditionalData = {
            selfDeclaredPep: form.pep,
            acceptedTacVersion: '2023-03-01',
            selfDeclaredLinksToHighRiskCountries: form.linksToHighRiskCountries,
        };

        if (form.whitelisted && props.merchant) {
            data.scaWhitelistedMerchantIds = [props.merchant.id];
        }

        trackClick('additional_information', 'finish_registration');
        props.onComplete?.(data);
    };

    const highRiskCountries = props.highRiskCountries
        .map((highRiskCountry) => countries.find((c) => c.countryCode === highRiskCountry)?.name ?? highRiskCountry)
        .join(t('additionalDetails.or'));

    const updateForm = (field: keyof AdditionalDataFormForm, value: boolean) => {
        setForm((form) => ({ ...form, [field]: value }));
        setShowInfoDialog(false);
        next();
    };

    return (
        <Page align="center">
            {step.type === 'pep' && (
                <AdditionalDataQuestion
                    title={t('additionalDetails.pep.title')}
                    icon={<BuildingBankRegular />}
                    confirmAction={{
                        title: t('additionalDetails.pep.confirm'),
                        onClick: () => updateForm('pep', false),
                    }}
                    declineAction={{ title: t('additionalDetails.pep.decline'), onClick: () => setShowInfoDialog('confirmation') }}
                >
                    <Body className={classes.message}>
                        <Trans
                            t={t}
                            i18nKey="additionalDetails.pep.message"
                            components={{ infoLink: <Link inline onClick={() => setShowInfoDialog('info')} /> }}
                        />
                    </Body>

                    <PepDialog
                        open={!!showInfoDialog}
                        onOpenChange={() => setShowInfoDialog(false)}
                        onConfirm={showInfoDialog === 'confirmation' ? (confirmed) => updateForm('pep', confirmed) : undefined}
                    />
                </AdditionalDataQuestion>
            )}

            {step.type === 'trustedBeneficiary' && (
                <AdditionalDataQuestion
                    title={t('additionalDetails.trustedBeneficiary.title')}
                    icon={<ShieldKeyholeRegular />}
                    confirmAction={{
                        title: t('additionalDetails.trustedBeneficiary.confirm'),
                        onClick: () => updateForm('whitelisted', true),
                    }}
                    declineAction={{
                        title: t('additionalDetails.trustedBeneficiary.decline'),
                        onClick: () => updateForm('whitelisted', false),
                    }}
                >
                    <Body className={classes.message}>
                        <Trans
                            t={t}
                            i18nKey="additionalDetails.trustedBeneficiary.message"
                            values={{ merchant: step.merchant.name }}
                            components={{ infoLink: <Link inline onClick={() => setShowInfoDialog('info')} /> }}
                        />
                    </Body>

                    <TrustedBeneficiaryDialog
                        merchant={step.merchant.name}
                        open={!!showInfoDialog}
                        onOpenChange={() => setShowInfoDialog(false)}
                    />
                </AdditionalDataQuestion>
            )}

            {step.type === 'highRiskCountries' && (
                <AdditionalDataQuestion
                    title={t('additionalDetails.highRiskCountries.title', { countries: highRiskCountries })}
                    icon={<MoneyHandRegular />}
                    confirmAction={{
                        title: t('additionalDetails.highRiskCountries.confirm'),
                        onClick: () => updateForm('linksToHighRiskCountries', false),
                    }}
                    declineAction={{
                        title: t('additionalDetails.highRiskCountries.decline'),
                        onClick: () => setShowInfoDialog('confirmation'),
                    }}
                >
                    <Body className={classes.message}>
                        <Trans
                            t={t}
                            i18nKey="additionalDetails.highRiskCountries.message"
                            values={{ countries: highRiskCountries }}
                            components={{ infoLink: <Link inline onClick={() => setShowInfoDialog('info')} /> }}
                        />
                    </Body>

                    <FinancialTiesDialog
                        open={!!showInfoDialog}
                        onOpenChange={() => setShowInfoDialog(false)}
                        onConfirm={
                            showInfoDialog === 'confirmation' ? (confirmed) => updateForm('linksToHighRiskCountries', confirmed) : undefined
                        }
                        highRiskCountries={highRiskCountries}
                    />
                </AdditionalDataQuestion>
            )}

            {step.type === 'tac' && (
                <AdditionalDataQuestion
                    title={t('additionalDetails.termAndConditions.title')}
                    icon={<ClipboardTextEditRegular />}
                    confirmAction={{
                        title: t('additionalDetails.termAndConditions.confirm'),
                        onClick: () => submit(),
                        disabled: loading,
                    }}
                >
                    <Body className={classes.message}>
                        <Trans
                            t={t}
                            i18nKey="additionalDetails.termAndConditions.message"
                            components={{
                                termsLink: <Link inline onClick={() => setShowTermsDialog(true)} />,
                                privacyLink: <Link inline onClick={() => setShowPrivacyDialog(true)} />,
                            }}
                        />
                    </Body>

                    <TermsAndConditionsDialog open={showTermsDialog} onOpenChange={() => setShowTermsDialog(false)} />
                    <PrivacyPolicyDialog open={showPrivacyDialog} onOpenChange={() => setShowPrivacyDialog(false)} />
                </AdditionalDataQuestion>
            )}
        </Page>
    );
};

const useStyles = makeStyles({
    questions: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalXXXL,
    },
    message: {
        whiteSpace: 'pre-wrap',
    },
});
