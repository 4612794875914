import { CheckmarkCircleRegular, InfoRegular } from '@fluentui/react-icons';
import React, { lazy, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { trackClick } from '@zastrpay/analytics';
import { localStorage } from '@zastrpay/common';
import { Body, Dialog } from '@zastrpay/components';
import { KycRequestPage, useCurrentKycRequest, useKycRequest } from '@zastrpay/kyc-requests';
import { MessagePage } from '@zastrpay/layout';

const VerifyEmailComponent = lazy(() => import('@zastrpay/auth-flow').then((module) => ({ default: module.VerifyEmail })));

export type VerifyEmailProps = {
    onVerify?: () => void;
    onSkipped?: () => void;
};

const SKIP_EMAIL_LIMIT = 100;
const SKIP_EMAIL_KEY = 'emailSkipped';

type SkipEmailState = {
    count: number;
    date?: Date;
};

const initialSkipped = { count: 0 };

export const VerifyEmail: React.FC<VerifyEmailProps> = ({ onVerify, onSkipped }) => {
    const { t } = useTranslation('payment');
    const { submit, skip, complete } = useKycRequest();
    const { count } = useMemo(() => localStorage.retrieve<SkipEmailState>(SKIP_EMAIL_KEY) ?? initialSkipped, []);
    const navigate = useNavigate();

    const [skipConfirmation, setSkipConfirmation] = useState(false);
    const [showSuccessPage, setShowSuccessPage] = useState(false);

    const { kycRequest } = useCurrentKycRequest();

    if (!kycRequest) {
        throw new Error('KycRequest is required');
    }

    const allowSkip = kycRequest.type === 'EmailVerification' && count < SKIP_EMAIL_LIMIT;

    const handleSkip = async () => {
        if (kycRequest.state !== 'Pending') {
            setSkipConfirmation(true);
        } else {
            await confirmSkip();
        }
    };

    const confirmSkip = () => {
        trackClick('email_verify', 'no_access_continue');

        localStorage.store(SKIP_EMAIL_KEY, { count: count + 1, date: new Date() });

        skip(kycRequest);
        onSkipped?.();
    };

    const verificationComplete = () => {
        onVerify?.();
        complete();
    };

    const submitRequest = () => {
        submit(kycRequest);
        setShowSuccessPage(true);
    };

    if (showSuccessPage) {
        return (
            <MessagePage
                icon={<CheckmarkCircleRegular />}
                title={t('emailVerification.verificationSuccess.title')}
                message={t('emailVerification.verificationSuccess.subTitle')}
                action={t('emailVerification.verificationSuccess.continue')}
                onAction={verificationComplete}
            />
        );
    }

    return (
        <>
            <KycRequestPage kycRequest={kycRequest} onSkip={() => navigate('/intent')} skippable>
                <VerifyEmailComponent onVerify={submitRequest} onNoAccess={allowSkip ? handleSkip : undefined} />
            </KycRequestPage>

            <Dialog
                open={skipConfirmation}
                align="bottom"
                title={t('emailVerification.bypassDialog.title')}
                icon={<InfoRegular />}
                onOpenChange={setSkipConfirmation}
                dismissible={false}
                actions={[
                    { text: t('emailVerification.bypassDialog.skip'), onClick: confirmSkip },
                    {
                        text: t('emailVerification.bypassDialog.verify'),
                        style: 'preferred',
                        onClick: () => setSkipConfirmation(false),
                    },
                ]}
            >
                <Body block>{t('emailVerification.bypassDialog.subTitle')}</Body>
            </Dialog>
        </>
    );
};
