import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';

import { isResponseError } from '@zastrpay/common';
import { Currency } from '@zastrpay/components';

import { reserveAccrual } from './api';
import { AccrualFailedErrorDetails, ExceededLimitThresholdDetails, LimitDirection, MerchantCategoryCode } from './models';

type TransactionLimitsContext = {
    checkLimits: (request: TransactionLimitsRequest, options?: TransactionLimitsOptions) => Promise<TransactionLimitsResponse>;
    isLoaded: boolean;
    error: unknown;
};

export type TransactionLimitsRequest = {
    customerId: string;
    sessionId: string;
    amount: number;
    currency: Currency;
    limitDirection: LimitDirection;
    merchantCategoryCode?: MerchantCategoryCode;
};

export type TransactionLimitsOptions = {
    useCache: boolean;
    allowedLimitIds?: string[];
};

export type TransactionLimitsResponse = {
    exceededLimits: ExceededLimitThresholdDetails[];
    availableBalance: number | undefined;
};

const Context = createContext<TransactionLimitsContext | null>(null);

export const TransactionLimitsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [error, setError] = useState<unknown>();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [limitsResponse, setLimitsResponse] = useState<TransactionLimitsResponse>({
        exceededLimits: [],
        availableBalance: undefined,
    });

    const calculateAvailableBalance = (limits: ExceededLimitThresholdDetails[]): number | undefined => {
        const onlyAmountLimits = limits.length > 0 && limits.every((limit) => limit.thresholdType === 'Amount');
        if (!onlyAmountLimits) {
            return undefined;
        }

        const values = limits.map((limit) => limit.remainingBalance ?? 0);
        const minAvailableBalance = Math.floor(Math.min(...values));

        return minAvailableBalance > 0 ? minAvailableBalance : undefined;
    };

    const checkLimits = useCallback(
        async (
            { customerId, sessionId, amount, currency, limitDirection, merchantCategoryCode }: TransactionLimitsRequest,
            options: TransactionLimitsOptions = { useCache: true },
        ): Promise<TransactionLimitsResponse> => {
            try {
                if (isLoaded && options.useCache && !error) {
                    return limitsResponse;
                }

                setError(null);
                setIsLoaded(false);

                await reserveAccrual(customerId, sessionId, {
                    amount,
                    currency,
                    limitDirection,
                    merchantCategoryCode,
                    skipThresholdChecks: false,
                    limitPreCheckOnly: true,
                });

                const response = { exceededLimits: [], availableBalance: undefined };
                setLimitsResponse(response);

                return response;
            } catch (error) {
                if (isResponseError(error, 'OneOrMoreLimitThresholdsExceeded')) {
                    const details = error.details as AccrualFailedErrorDetails;
                    const exceededLimits = details?.exceededLimitThresholds ?? [];
                    // Filter out limits that are not in the allowedLimitIds
                    // This is a workaround for the backward compatibility with old limits because we are suppose only enforce the new CRA limits
                    const allowedLimits = options.allowedLimitIds
                        ? exceededLimits.filter((limit) => options.allowedLimitIds?.includes(limit.limitId))
                        : exceededLimits;

                    const availableBalance = calculateAvailableBalance(allowedLimits);

                    const response = { exceededLimits: allowedLimits, availableBalance };
                    setLimitsResponse(response);

                    return response;
                }

                const response = { exceededLimits: [], availableBalance: undefined };
                setLimitsResponse(response);
                setError(error);

                return response;
            } finally {
                setIsLoaded(true);
            }
        },
        [error, isLoaded, limitsResponse],
    );

    return <Context.Provider value={{ checkLimits, isLoaded, error }}>{children}</Context.Provider>;
};

export const useTransactionLimits = (): TransactionLimitsContext => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useTransactionLimits must be used within a TransactionLimitsProvider');
    }

    return context;
};
