import { Time } from '@zastrpay/common';
import { Currency } from '@zastrpay/components';
import { Address } from '@zastrpay/customers';

export type KycRequestType =
    | 'SofQuestionnaire'
    | 'SofDocument'
    | 'SowQuestionnaire'
    | 'SowDocument'
    | 'PoaDocument'
    | 'PoiDocument'
    | 'EmailVerification'
    | 'InitialVerification'
    | 'SelfDeclaredLinksQuestionnaire'
    | 'SelfDeclaredPepQuestionnaire';

export type KycRequestState =
    | 'Pending'
    | 'PendingOverdue'
    | 'DataRejected'
    | 'DataReceived'
    | 'PendingExternalVerification'
    | 'PendingManualReview'
    | 'Completed';

export type DataMismatchReason =
    | 'AddressMismatch'
    | 'NameMismatch'
    | 'NationalityMismatch'
    | 'PlaceOfBirthMismatch'
    | 'DateOfBirthMismatch';

export type InvalidDocumentReason =
    | 'InvalidDocumentType'
    | 'Expired'
    | 'NoDate'
    | 'TooOld'
    | 'InsufficientInformation'
    | 'InvalidDocument'
    | 'WrongFormatCannotOpenDocument'
    | 'MissingInformation'
    | 'InadmissibleOther'
    | 'Damaged'
    | 'AlreadyOnFile';

export type PoorDocumentQualityReason = 'ImageTooDark' | 'Blurry' | 'Illegible' | 'Cropped';

export type KycRequestStateDetails = {
    dataMismatchReason?: DataMismatchReason;
    invalidDocumentReason?: InvalidDocumentReason;
    poorDocumentQualityReason?: PoorDocumentQualityReason;
};

export type Assignment = {
    id: string;
    questionnaireId: string;
};

export type KycRequestCreationDetails = {
    highRiskCountries?: string[];
};

export type ReferenceEntityType = 'ReKycVerification' | 'PeriodicReview' | 'Limit' | 'AdHoc';

export type ReferenceEntity = {
    id: string;
    type: ReferenceEntityType;
};

export type KycRequest = {
    id: string;
    customerId: string;

    dueOn?: Date;
    dueAfter?: Time;
    assignment?: Assignment;
    type: KycRequestType;
    state: KycRequestState;
    stateDetails?: KycRequestStateDetails;
    creationDetails?: KycRequestCreationDetails;

    createdOn: Date;
    lastModifiedOn: Date;
    lastCorrelationId: string;
    referenceEntities: ReferenceEntity[];
};

export type DocumentType =
    | 'BankAccountStatement'
    | 'Betslip'
    | 'IncomeTaxStatement'
    | 'Payslip'
    | 'OtherSourceOfFundsOrSourceOfWealth'
    | 'AddressRegistration'
    | 'UtilityBill'
    | 'IdCard'
    | 'Passport'
    | 'ResidencePermit'
    | 'RefugeeDocument'
    | 'OtherProofOfAddress';

export type KycDocument = {
    id: string;
    type: DocumentType;
    number?: string;
    issuedBy?: string;
    expiresOn?: string;
};

export type SubmitDataRequest = {
    address?: Address;
    identityData?: IdentityData;
    documents?: KycDocument[];
};

export type IdentityData = {
    firstName: string;
    lastName: string;
    maidenName?: string;
    middleName?: string;
    dateOfBirth: string;
    placeOfBirth: string;
    countryOfBirth?: string;
    nationality: string;
};

export type SuggestedAmount = {
    amount: number;
    currency: Currency;
};

export const isRequiredKycRequest = (request: KycRequest): boolean =>
    request.type === 'InitialVerification' || request.state === 'DataRejected' || request.state === 'PendingOverdue';

export const isPendingKycRequest = (request: KycRequest): boolean =>
    request.state === 'Pending' || request.state === 'PendingOverdue' || request.state === 'DataRejected';

export const sortByDueDate = (first: KycRequest, second: KycRequest) => {
    if (first.dueOn && second.dueOn) {
        return first.dueOn.getTime() - second.dueOn.getTime();
    } else if (!first.dueOn) {
        return 1;
    } else if (!second.dueOn) {
        return -1;
    } else {
        return first.createdOn.getTime() - second.createdOn.getTime();
    }
};

export const getRequestTypeWeight = (kycRequestType: KycRequestType): number => {
    // This function defines a custom order for KYC requests based on their type.
    // for future reference: weight can be the same for multiple types, then the order will be based on the due date.
    switch (kycRequestType) {
        case 'InitialVerification':
            return 1;
        case 'EmailVerification':
            return 2;
        case 'SowQuestionnaire':
            return 3;
        case 'SofQuestionnaire':
            return 4;
        case 'SelfDeclaredPepQuestionnaire':
            return 5;
        case 'SelfDeclaredLinksQuestionnaire':
            return 6;
        case 'PoiDocument':
            return 7;
        case 'PoaDocument':
            return 8;
        case 'SofDocument':
            return 9;
        case 'SowDocument':
            return 10;
    }
};

export const sortByType = (first: KycRequest, second: KycRequest) => {
    const firstWeight = getRequestTypeWeight(first.type);
    const secondWeight = getRequestTypeWeight(second.type);
    if (firstWeight === secondWeight) {
        return sortByDueDate(first, second);
    }
    return firstWeight - secondWeight;
};
