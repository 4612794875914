import { ChevronLeft24Regular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';

import { Button } from '@zastrpay/components';

export type BackButtonProps = {
    onClick: () => void;
};

export const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
    const { t } = useTranslation('layout');

    return <Button appearance="transparent" size="large" onClick={onClick} icon={<ChevronLeft24Regular />} title={t('back')} />;
};
