/* eslint-disable no-extend-native */

// Array.prototype.at, Promise.allSettled, Promise.withResolvers need to be polyfilled
// for react-pdf to work in older browsers (e.g. < iOS 17.4) https://github.com/wojtekmaj/react-pdf

// polyfill for Array.prototype.at
if (!Array.prototype.at) {
    Array.prototype.at = function (index) {
        if (this == null) {
            throw new TypeError('Array.prototype.at called on null or undefined');
        }
        const len = this.length;
        if (len === 0) {
            return undefined;
        }
        const relativeIndex = index < 0 ? len + index : index;
        if (relativeIndex < 0 || relativeIndex >= len) {
            return undefined;
        }
        return this[relativeIndex];
    };
}

// polyfill Promise.allSettled
if (!Promise.allSettled) {
    Promise.allSettled = function <T extends readonly unknown[] | []>(
        promises: T,
    ): Promise<{ -readonly [P in keyof T]: PromiseSettledResult<Awaited<T[P]>> }> {
        return Promise.all(
            promises.map((promise) =>
                Promise.resolve(promise).then(
                    (value) => ({ status: 'fulfilled', value }),
                    (reason) => ({ status: 'rejected', reason }),
                ),
            ),
        ) as Promise<{ -readonly [P in keyof T]: PromiseSettledResult<Awaited<T[P]>> }>;
    };
}

// polyfill Promise.withResolvers
if (!Promise.withResolvers) {
    Promise.withResolvers = function <T>(): {
        promise: Promise<T>;
        resolve: (value: T | PromiseLike<T>) => void;
        reject: (reason?: unknown) => void;
    } {
        let resolve: (value: T | PromiseLike<T>) => void;
        let reject: (reason?: unknown) => void;
        const promise = new Promise<T>((res, rej) => {
            resolve = res;
            reject = rej;
        });
        return { promise, resolve: resolve!, reject: reject! };
    };
}
