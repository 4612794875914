import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import { Account, get as getAccount } from '@zastrpay/accounts';
import { useAuth } from '@zastrpay/auth';
import { ensureResponseError, isResponseError, isValidationError, TranslatableError, ValidationError } from '@zastrpay/common';
import { Alert, ErrorTrans } from '@zastrpay/components';
import { useKycRequest } from '@zastrpay/kyc-requests';
import { Page } from '@zastrpay/layout';
import {
    CreateTransactionIntent,
    isIdRequired,
    isTransactionIntentType,
    TransactionIntent,
    TransactionIntentInformation,
    ViewTransactionIntent,
    ViewTransactionState,
} from '@zastrpay/transaction-intents';
import { AuthorizeTransaction, create as createTransaction, CreateTransaction, Transaction } from '@zastrpay/transactions';

import { useApp } from '../AppProvider';
import {
    getTransactionIntentTypeFromDirection,
    getTransactionIntentTypeFromRedirectTypeOrDirection,
    TransactionData,
} from '../auth/models';
import { useCustomerPermissions } from '../customer/CustomerPermissionsProvider';
import { useTransactionKyc } from '../kyc-request/use-transaction-kyc';
import { useSessionNavigation } from '../layout/SessionNavigationProvider';
import { Merchant } from '../merchants/models';
import { cancel, create, get, getList, tryGet } from './api';
import { TransactionIntentFilter } from './models';
import { TransactionTypeSelection } from './TransactionTypeSelection';
import { WalletTransferConfirmation } from './WalletTransferConfirmation';
import { WalletTransferInsufficientFunds } from './WalletTransferInsufficientFunds';

type ErrorStep = {
    type: 'error';
    error: TranslatableError;
};

type TransactionTypeSelectionStep = {
    type: 'transaction-type-selection';
    transaction: TransactionData;
};

type WalletTransferConfirmationStep = {
    type: 'wallet-transfer-confirmation';
    transaction: TransactionData;
    account?: Account;
};

type WalletTransferInsufficientFundsStep = {
    type: 'wallet-transfer-insufficient-funds';
    transaction: TransactionData;
    account?: Account;
};

type WalletTransferApprovalStep = {
    type: 'wallet-transfer-approval';
    transaction: Transaction;
};

type InformationStep = {
    type: 'information';
    transaction: TransactionData;
};

type TransactionIntentStep = {
    type: 'transaction-intent';
    intent: TransactionIntent;
};

type TransactionProcessedStep = {
    type: 'transaction-processed';
    transaction: Transaction;
};

type Step =
    | ErrorStep
    | InformationStep
    | TransactionIntentStep
    | TransactionProcessedStep
    | TransactionTypeSelectionStep
    | WalletTransferConfirmationStep
    | WalletTransferInsufficientFundsStep
    | WalletTransferApprovalStep;

export const TransactionIntentDetails: React.FC = () => {
    const { t, i18n } = useTranslation('payment');
    const { t: tTrxInt } = useTranslation('transaction-intents');

    const { customerId } = useAuth();
    const { existingIntent } = useCustomerPermissions();
    const { merchant, redirectSession, setCodeGenerated, setCodeFinalized } = useApp();
    const navigate = useNavigate();

    const { isChecksCompleted, limitRequests } = useTransactionKyc({ useCache: false });
    const { pendingRequests, loaded } = useKycRequest();

    const sessionNavigation = useSessionNavigation();

    const [step, setStep] = useState<Step>();

    const canLoad = customerId && merchant && redirectSession;

    useEffect(() => {
        if (!isChecksCompleted || !loaded) {
            return;
        }

        if (pendingRequests.length || limitRequests.length) {
            navigate('/kyc-request');
        }

        loadContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChecksCompleted, loaded]);

    const handleError = (error: unknown) => {
        // if handled error not defined we will be redirected before
        setStep({ type: 'error', error: ensureResponseError(error) });
    };

    const loadIntent = async (sessionId: string, customerId: string, transaction: TransactionData, merchant: Merchant) => {
        if (transaction.type === 'MerchantToCustomerTransfer') {
            setStep({ type: 'wallet-transfer-confirmation', transaction });
        } else {
            const filter: TransactionIntentFilter = {
                customerId: customerId,
                merchantId: merchant.id,
                intentType: getTransactionIntentTypeFromRedirectTypeOrDirection(transaction.direction, transaction.type),
                type: 'ActiveByCustomerAndMerchant',
            };

            try {
                //contains all active intents except the current one as this was already checked above
                const currentIntent = await tryGet(sessionId);

                if (currentIntent) {
                    setStep({ type: 'transaction-intent', intent: currentIntent });
                } else {
                    const intents = (await getList(filter)).items;

                    if (intents.length) {
                        setStep({
                            type: 'error',
                            error: new ValidationError('ExistingIntent'),
                        });
                    } else {
                        if (transaction.direction === 'MerchantToCustomer') {
                            setStep({ type: 'transaction-type-selection', transaction });
                        } else if (transaction.direction === 'CustomerToMerchant') {
                            getAccount(customerId).then((account) => {
                                if (account.balance > transaction.amount) {
                                    setStep({ type: 'wallet-transfer-confirmation', transaction, account });
                                } else {
                                    setStep({ type: 'wallet-transfer-insufficient-funds', transaction, account });
                                }
                            });
                            setStep({ type: 'transaction-type-selection', transaction });
                        } else {
                            setStep({ type: 'information', transaction });
                        }
                    }
                }
            } catch (error) {
                handleError(error);
            }
        }
    };

    const loadContent = () => {
        if (canLoad) {
            if (redirectSession?.type === 'ExistingTransactionIntent') {
                setStep({ type: 'transaction-intent', intent: existingIntent! });
            } else if (redirectSession?.type === 'NewTransactionIntent') {
                loadIntent(redirectSession.id, customerId, redirectSession.transactionData, merchant);
            }
        }
    };

    const createIntent = async (customerId: string, merchantId: string) => {
        if (redirectSession?.type === 'NewTransactionIntent') {
            const data = redirectSession.transactionData;
            const intentData: CreateTransactionIntent = {
                ...data,
                customerId,
                merchantId,
                locale: i18n.language.substring(0, 2),
                type: getTransactionIntentTypeFromRedirectTypeOrDirection(data.direction, data.type),
            };

            try {
                const transactionIntent = await create(redirectSession.id, intentData);

                setCodeGenerated();
                setStep({ type: 'transaction-intent', intent: transactionIntent });
            } catch (error) {
                handleError(error);
            }
        }
    };

    const updateIntent = (intent: TransactionIntent) => {
        setStep({ type: 'transaction-intent', intent });
    };

    const finalizeIntent = (transaction: Transaction) => {
        setCodeFinalized();
        setStep({ type: 'transaction-processed', transaction });
    };

    const cancelIntent = async (transactionIntent: TransactionIntent) => {
        try {
            const cancelledIntent = await cancel(transactionIntent.id);
            setStep({ type: 'transaction-intent', intent: cancelledIntent });
        } catch (error) {
            handleError(error);
        }
    };

    const expireIntent = (transactionIntent: TransactionIntent) => {
        setStep({
            type: 'transaction-intent',
            intent: { ...transactionIntent, state: 'Expired' },
        });
    };

    const refreshIntent = async (transactionIntent: TransactionIntent) => {
        try {
            const refreshedIntent = await get(transactionIntent.id);
            setStep({ type: 'transaction-intent', intent: refreshedIntent });
        } catch (error) {
            handleError(error);
        }
    };

    const cashSelection = (transactionData: TransactionData) => {
        transactionData.type = getTransactionIntentTypeFromDirection(transactionData.direction);
        return setStep({ type: 'information', transaction: transactionData });
    };

    const createWalletTransfer = async (transactionData: CreateTransaction) => {
        if (redirectSession?.type === 'NewTransactionIntent') {
            const transaction = await createTransaction(redirectSession?.id, transactionData);
            if (transaction.state === 'PendingApproval') {
                return setStep({ type: 'wallet-transfer-approval', transaction });
            } else {
                setCodeFinalized();
                return setStep({ type: 'transaction-processed', transaction });
            }
        }
    };

    const showTransactionFinalized = (transaction: Transaction) => {
        setCodeFinalized();
        setStep({ type: 'transaction-processed', transaction });
    };

    const showTransactionIntentInformation = (transaction: TransactionData) => {
        transaction.type = getTransactionIntentTypeFromDirection(transaction.direction);
        setStep({ type: 'information', transaction: transaction });
    };

    if (!canLoad) {
        return <Navigate to="/error/expired" />;
    }

    switch (step?.type) {
        case 'error':
            return (
                <Page>
                    <Alert type="error">
                        {isValidationError(step.error, 'ExistingIntent') ? (
                            t('transactionIntent.error.validation', {
                                context: step.error.code,
                                merchant: merchant.displayName ?? merchant.name,
                            })
                        ) : (
                            <ErrorTrans
                                t={tTrxInt}
                                error={step.error}
                                values={{
                                    intentType:
                                        redirectSession.type === 'NewTransactionIntent' &&
                                        isTransactionIntentType(redirectSession.transactionData?.type)
                                            ? tTrxInt('error.intentType', { context: redirectSession.transactionData.type })
                                            : undefined,
                                }}
                                additionalContext={
                                    (isResponseError(step.error, 'CustomerPermissionDenied') &&
                                        (step.error.details?.customerPermissionDeniedReason as string)) ||
                                    ((isResponseError(step.error, 'OneOrMoreLimitThresholdsExceeded') ||
                                        isResponseError(step.error, 'LimitAmountThresholdExceeded') ||
                                        isResponseError(step.error, 'LimitTimeThresholdExceeded')) &&
                                        (step.error.details?.limitThresholdExceededReason as string)) ||
                                    undefined
                                }
                            />
                        )}
                    </Alert>
                </Page>
            );
        case 'transaction-type-selection':
            return (
                <TransactionTypeSelection
                    transactionData={step.transaction}
                    merchant={merchant}
                    onCashSelection={cashSelection}
                    onCreateWalletTransfer={createWalletTransfer}
                ></TransactionTypeSelection>
            );
        case 'wallet-transfer-confirmation':
            return (
                <WalletTransferConfirmation
                    transactionData={step.transaction}
                    merchant={merchant}
                    account={step.account}
                    onConfirmation={createWalletTransfer}
                ></WalletTransferConfirmation>
            );
        case 'wallet-transfer-insufficient-funds':
            return (
                <WalletTransferInsufficientFunds
                    transactionData={step.transaction}
                    account={step.account}
                    onContinue={() => showTransactionIntentInformation(step.transaction)}
                ></WalletTransferInsufficientFunds>
            );
        case 'wallet-transfer-approval':
            return (
                <AuthorizeTransaction
                    transaction={step.transaction}
                    merchant={merchant}
                    onAuthorize={showTransactionFinalized}
                ></AuthorizeTransaction>
            );
        case 'information':
            return (
                <TransactionIntentInformation
                    onContinue={() => createIntent(customerId, merchant.id)}
                    transactionIntentType={getTransactionIntentTypeFromRedirectTypeOrDirection(
                        step.transaction.direction,
                        step.transaction.type,
                    )}
                    idRequired={isIdRequired(
                        getTransactionIntentTypeFromRedirectTypeOrDirection(step.transaction.direction, step.transaction.type),
                        step.transaction.amount,
                    )}
                />
            );
        case 'transaction-processed':
            return (
                <ViewTransactionState
                    merchant={merchant}
                    transaction={step.transaction}
                    onFailure={sessionNavigation.failure}
                    onSuccess={sessionNavigation.success}
                />
            );
        case 'transaction-intent':
            return (
                <ViewTransactionIntent
                    merchant={merchant}
                    intent={step.intent}
                    onAuthorize={updateIntent}
                    onCancel={() => cancelIntent(step.intent)}
                    onExpire={() => expireIntent(step.intent)}
                    onRefresh={() => refreshIntent(step.intent)}
                    onFinalize={finalizeIntent}
                    onSuccess={sessionNavigation.success}
                    onCancelled={() => sessionNavigation.cancel(true)}
                    onFailure={sessionNavigation.failure}
                />
            );
        case undefined:
            // TODO: should we handle this case?
            return null;
    }
};
