import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ActiveKycRequest, KycRequest, RequiredKycRequest, sortByType, useKycRequest } from '@zastrpay/kyc-requests';
import { useLayout } from '@zastrpay/layout';

import { useApp } from '../AppProvider';
import { featureToggles } from '../config';
import { useSessionNavigation } from '../layout/SessionNavigationProvider';
import { CancelSession } from '../session-cancellation/CancelSession';
import { SuggestedAmountRetryDialog } from './SuggestedAmountRetryDialog';
import { useTransactionKyc } from './use-transaction-kyc';

type RequiredKycStep = {
    type: 'required-kyc';
    requiredRequests: KycRequest[];
};

type NextKycRequestStep = {
    type: 'next-kyc-request';
    kycRequest: KycRequest;
};

type Step = RequiredKycStep | NextKycRequestStep;

export const KycRequestPage: React.FC = () => {
    const { merchant } = useApp();
    const { setHeaderSlot } = useLayout();
    const { cancel } = useSessionNavigation();

    const { isChecksCompleted, limitRequests, suggestedAmount } = useTransactionKyc({ useCache: true });
    const { pendingRequests } = useKycRequest();

    const [retryConfirmationOpen, setRetryConfirmationOpen] = useState(false);

    const [step, setStep] = useState<Step>();

    const navigate = useNavigate();

    const cancelSession = useCallback(() => cancel(), [cancel]);
    const backToMerchant = useCallback(() => {
        if (suggestedAmount) {
            setRetryConfirmationOpen(true);
        } else {
            cancelSession();
        }
    }, [suggestedAmount, cancelSession]);

    useEffect(() => {
        if (!isChecksCompleted) {
            return;
        }

        // TODO: remove feature toggle block when transaction limits are enabled in production
        if (!featureToggles.limitsCheckEnabled) {
            if (pendingRequests.length > 0) {
                setStep({ type: 'next-kyc-request', kycRequest: pendingRequests[0] });
            } else {
                navigate('/');
            }

            return;
        }

        if (limitRequests.length > 0) {
            setStep({
                type: 'required-kyc',
                requiredRequests: limitRequests.sort(sortByType),
            });
        } else if (pendingRequests.length > 0) {
            setStep({ type: 'next-kyc-request', kycRequest: pendingRequests[0] });
        } else {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChecksCompleted, limitRequests, pendingRequests]);

    useEffect(() => {
        if (step?.type === 'required-kyc') {
            const unregisterHandle = setHeaderSlot('right', <CancelSession dialog="required-kyc" />);

            return () => unregisterHandle();
        }
    }, [step, setHeaderSlot]);

    switch (step?.type) {
        case 'required-kyc':
            return (
                <>
                    <SuggestedAmountRetryDialog
                        open={retryConfirmationOpen}
                        onOpenChange={setRetryConfirmationOpen}
                        amount={suggestedAmount}
                    />
                    <RequiredKycRequest
                        kycRequestList={step.requiredRequests}
                        suggestedAmount={suggestedAmount}
                        merchantName={merchant?.displayName ?? merchant?.name}
                        onCancel={backToMerchant}
                    />
                </>
            );
        case 'next-kyc-request':
            return <ActiveKycRequest kycRequest={step.kycRequest} mode="page" skippable />;
        case undefined:
            return null;
    }
};
