import { Dismiss24Regular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';

import { Button } from '@zastrpay/components';

export type CloseButtonProps = {
    onClick: () => void;
};

export const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
    const { t } = useTranslation('layout');

    return <Button appearance="transparent" size="large" onClick={onClick} icon={<Dismiss24Regular />} title={t('close')} />;
};
