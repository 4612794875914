/* eslint-disable no-restricted-imports */
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { ProgressBarProps as FuiFuiProgressBar, ProgressBar as FuiProgressBar } from '@fluentui/react-progress';

import { tokens } from '@zastrpay/theme';

export type ProgressBarProps = Pick<FuiFuiProgressBar, 'shape' | 'value' | 'className'> & React.AriaAttributes;

export const ProgressBar: React.FC<ProgressBarProps> = ({ className, ...props }) => {
    const classes = useStyles();

    return <FuiProgressBar thickness="large" bar={{ className: mergeClasses(classes.bar, className) }} {...props} />;
};

const useStyles = makeStyles({
    bar: {
        transitionProperty: 'width, background-color',
        backgroundColor: tokens.customPaletteGreenDark,
    },
});
