import { makeStyles } from '@fluentui/react-components';
import { ErrorCircleRegular } from '@fluentui/react-icons';
import { FC, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BodyStrong, Dialog, DialogProps, FormattedCurrency } from '@zastrpay/components';
import { SuggestedAmount } from '@zastrpay/kyc-requests';
import { tokens } from '@zastrpay/theme';

import { useApp } from '../AppProvider';
import { useSessionNavigation } from '../layout/SessionNavigationProvider';

export type SuggestedAmountRetryDialogProps = Pick<DialogProps, 'open' | 'onOpenChange'> & {
    amount: SuggestedAmount | undefined;
};

export const SuggestedAmountRetryDialog: FC<SuggestedAmountRetryDialogProps> = ({ amount, ...dialogProps }) => {
    const classes = useStyles();
    const { t } = useTranslation('payment');

    const { merchant } = useApp();
    const { cancel } = useSessionNavigation();

    const cancelSession = useCallback(() => cancel(), [cancel]);

    const merchantName = merchant?.displayName ?? merchant?.name;
    const merchantTranslationContext = merchantName ? { merchant: merchantName } : { context: 'GenericMerchant' };

    return (
        <Dialog
            {...dialogProps}
            actions={[
                {
                    text: t('kycRetryDialog.back', merchantTranslationContext),
                    style: 'preferred',
                    onClick: cancelSession,
                },
            ]}
            icon={<ErrorCircleRegular className={classes.icon} />}
            align="bottom"
        >
            <BodyStrong block>
                <Trans
                    t={t}
                    i18nKey="kycRetryDialog.backConfirmation"
                    components={{
                        amount: <FormattedCurrency currency={amount?.currency} value={amount?.amount} format="sign" />,
                    }}
                />
            </BodyStrong>
        </Dialog>
    );
};

const useStyles = makeStyles({
    icon: {
        color: tokens.customPaletteRed,
    },
});
