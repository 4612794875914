import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@zastrpay/auth';
import { useErrorBoundary } from '@zastrpay/components';

import { skip as doSkip, submitData as doSubmitData, getList } from './api';
import { EMAIL_KYC_ENABLED } from './config';
import { isPendingKycRequest, isRequiredKycRequest, KycRequest, ReferenceEntityType, sortByType, SubmitDataRequest } from './models';

type RequiredKycRequestListState = {
    state: 'initial' | 'in-progress' | 'completed';
    remainingRequests: string[];
};

type KycRequestState = {
    loaded: boolean;
    completed: boolean;

    pendingRequests: KycRequest[];
    conditionalRequests: KycRequest[];

    skippedRequests: string[];
    requiredRequestListState: RequiredKycRequestListState;
};

export type KycRequestContext = {
    pendingRequests: KycRequest[];
    conditionalRequests: KycRequest[];

    loaded: boolean;
    completed: boolean;

    skip: (kycRequest: KycRequest) => void;
    seen: (kycRequest: KycRequest) => Promise<void>;
    submit: (kycRequest: KycRequest) => void;
    complete: () => void;
    submitData: (kycRequest: KycRequest, data: SubmitDataRequest, partial?: boolean) => Promise<void>;
    loadRequests: () => Promise<KycRequestsResponse>;
    findById: (id: string) => KycRequest | undefined;
    setRequiredRequestListInProgress: (loadedRequestList: string[]) => void;
    setRequiredRequestListCompleted: () => void;
    getRequiredRequestListProgressState: () => RequiredKycRequestListState['state'];
};

export type KycRequestsResponse = Pick<KycRequestContext, 'pendingRequests' | 'conditionalRequests'>;

export type KycRequestProviderProps = {
    prioritizeRequiredKyc?: boolean;
    /**
     * List of reference types that indicate conditional kyc requests
     */
    conditionalReferenceTypes?: ReferenceEntityType[];
};

const Context = createContext<KycRequestContext | null>(null);

const REACTIVATE_AFTER = 10_000;

export const KycRequestProvider: React.FC<PropsWithChildren<KycRequestProviderProps>> = ({
    conditionalReferenceTypes = [],
    prioritizeRequiredKyc = false,
    children,
}) => {
    const handle = useRef<ReturnType<typeof setTimeout>>();

    const { trackError } = useErrorBoundary();
    const { state, customerId, refreshCustomer } = useAuth();
    const navigate = useNavigate();

    const [{ loaded, pendingRequests, conditionalRequests, skippedRequests, completed, requiredRequestListState }, setState] =
        useState<KycRequestState>({
            loaded: false,
            completed: true,
            pendingRequests: [],
            conditionalRequests: [],
            skippedRequests: [],
            requiredRequestListState: {
                state: 'initial',
                remainingRequests: [],
            },
        });

    const submit = (kycRequest: KycRequest) => {
        if (!kycRequest) {
            throw new Error('KycRequest is required');
        }

        if (handle.current === undefined) {
            const updatedPendingRequests = pendingRequests.filter((request) => request.id !== kycRequest.id);
            const updatedConditionalRequests = conditionalRequests.filter((request) => request.id !== kycRequest.id);
            const updatedRequiredRequests = requiredRequestListState.remainingRequests.filter((requestId) => requestId !== kycRequest.id);

            setState({
                loaded: true,
                pendingRequests: updatedPendingRequests,
                conditionalRequests: updatedConditionalRequests,
                skippedRequests: [...skippedRequests, kycRequest.id],
                completed: false,
                requiredRequestListState: updateRequiredRequestListState(updatedRequiredRequests),
            });

            handle.current = setTimeout(() => loadRequests(), REACTIVATE_AFTER);
        }
    };

    const submitData = async (kycRequest: KycRequest, data: SubmitDataRequest, skipPendingExternalVerification?: boolean) => {
        if (!kycRequest) {
            throw new Error('KycRequest is required');
        }

        const updated = await doSubmitData(kycRequest.id, data);

        // if the request is still pending, we should wait for the external verification
        // the consumer of this api should handle the push notifications
        if (!skipPendingExternalVerification || updated.state !== 'PendingExternalVerification') {
            const updatedPendingRequests = pendingRequests.filter((request) => request.id !== kycRequest.id);
            const updatedConditionalRequests = conditionalRequests.filter((request) => request.id !== kycRequest.id);
            const updatedRequiredRequests = requiredRequestListState.remainingRequests.filter((requestId) => requestId !== kycRequest.id);

            setState({
                loaded: true,
                completed: false,
                pendingRequests: updatedPendingRequests,
                conditionalRequests: updatedConditionalRequests,
                skippedRequests,
                requiredRequestListState: updateRequiredRequestListState(updatedRequiredRequests),
            });
        }
    };

    const complete = () => {
        refreshCustomer().then(() => {
            setState((state) => ({ ...state, completed: state.pendingRequests.length === 0 }));
        });
    };

    const skip = (kycRequest: KycRequest) => {
        if (!kycRequest) {
            throw new Error('KycRequest is required');
        }

        if (kycRequest.state === 'Pending' || kycRequest.type === 'EmailVerification') {
            const updatedSkippedRequests = [...skippedRequests, kycRequest.id];
            const updatedPendingRequest = pendingRequests.filter((request) => !updatedSkippedRequests.includes(request.id));

            setState({
                loaded: true,
                pendingRequests: updatedPendingRequest,
                conditionalRequests,
                completed: updatedPendingRequest.length === 0,
                skippedRequests: updatedSkippedRequests,
                requiredRequestListState,
            });

            if (!completed) {
                navigate('/kyc-request');
            }
        }
    };

    const seen = async (kycRequest: KycRequest) => {
        if (!kycRequest) {
            throw new Error('KycRequest is required');
        }

        if (kycRequest.dueAfter) {
            const skipped = await doSkip(kycRequest.id);

            const updatedPendingRequests = pendingRequests.map((request) => (request.id === skipped.id ? skipped : request));
            const updatedConditionalRequests = conditionalRequests.map((request) => (request.id === skipped.id ? skipped : request));

            setState({
                loaded: true,
                completed: false,
                pendingRequests: updatedPendingRequests,
                conditionalRequests: updatedConditionalRequests,
                skippedRequests,
                requiredRequestListState,
            });
        }
    };

    const filterKycRequests = useCallback(
        (allRequests: KycRequest[], skippedRequests: string[]) => {
            const omitSkipped = allRequests.filter((request) => !skippedRequests.includes(request.id));

            const conditionalRequests = omitSkipped.filter(
                ({ referenceEntities }) =>
                    referenceEntities?.length && referenceEntities.every((entity) => conditionalReferenceTypes.includes(entity.type)),
            );

            // omit conditional requests from pending requests
            const pendingRequests = omitSkipped.filter(
                (request) => isPendingKycRequest(request) && !conditionalRequests.some((conditional) => conditional.id === request.id),
            );

            const requiredRequests: KycRequest[] = [];
            const skippableRequests: KycRequest[] = [];

            for (const request of pendingRequests) {
                if (!EMAIL_KYC_ENABLED && request.type === 'EmailVerification') {
                    continue;
                }

                if (isRequiredKycRequest(request)) {
                    requiredRequests.push(request);
                } else {
                    skippableRequests.push(request);
                }
            }

            requiredRequests.sort(sortByType);
            skippableRequests.sort(sortByType);
            conditionalRequests.sort(sortByType);

            const requiredRequestsHasBeenShown = requiredRequestListState.state !== 'initial';
            return {
                pendingRequests:
                    prioritizeRequiredKyc && requiredRequestsHasBeenShown ? requiredRequests : [...requiredRequests, ...skippableRequests],
                conditionalRequests,
            };
        },
        [conditionalReferenceTypes, prioritizeRequiredKyc, requiredRequestListState],
    );

    const loadRequests = useCallback(async () => {
        try {
            if (state === 'authenticated') {
                const requests = await getList(customerId);

                let result: KycRequestsResponse = { pendingRequests: [], conditionalRequests: [] };
                setState(({ skippedRequests, completed, requiredRequestListState }) => {
                    result = filterKycRequests(requests, skippedRequests);

                    return {
                        loaded: true,
                        failed: false,
                        completed: completed && result.pendingRequests.length === 0,
                        pendingRequests: result.pendingRequests,
                        conditionalRequests: result.conditionalRequests,
                        skippedRequests,
                        requiredRequestListState,
                    };
                });

                return result;
            }

            return { pendingRequests: [], conditionalRequests: [] };
        } catch (error) {
            trackError(error);

            setState({
                loaded: true,
                completed: true,
                pendingRequests: [],
                conditionalRequests: [],
                skippedRequests: [],
                requiredRequestListState: {
                    state: 'initial',
                    remainingRequests: [],
                },
            });

            return { pendingRequests: [], conditionalRequests: [] };
        } finally {
            if (handle.current !== undefined) {
                clearTimeout(handle.current);
                handle.current = undefined;
            }
        }
    }, [state, customerId, trackError, filterKycRequests]);

    const findById = useCallback(
        (id: string) => {
            const pending = pendingRequests.find((request) => request.id === id);
            if (pending) {
                return pending;
            }

            return conditionalRequests.find((request) => request.id === id);
        },
        [pendingRequests, conditionalRequests],
    );

    const updateRequiredRequestListState = useCallback(
        (remainingRequests: string[]): RequiredKycRequestListState => {
            if (requiredRequestListState.state === 'in-progress') {
                return {
                    state: remainingRequests.length === 0 ? 'completed' : 'in-progress',
                    remainingRequests,
                };
            }

            return requiredRequestListState;
        },
        [requiredRequestListState],
    );

    const setRequiredRequestListInProgress = useCallback((requestList: string[]) => {
        setState((state) => ({
            ...state,
            requiredRequestListState: {
                state: 'in-progress',
                remainingRequests: requestList,
            },
        }));
    }, []);

    const setRequiredRequestListCompleted = useCallback(() => {
        setState((state) => ({
            ...state,
            requiredRequestListState: {
                state: 'completed',
                remainingRequests: [],
            },
        }));
    }, []);

    const getRequiredRequestListProgressState = useCallback(() => requiredRequestListState.state, [requiredRequestListState.state]);

    useEffect(() => {
        loadRequests();
    }, [loadRequests]);

    return (
        <Context.Provider
            value={{
                loaded,
                completed,
                pendingRequests,
                conditionalRequests,
                submit,
                complete,
                loadRequests,
                seen,
                skip,
                submitData,
                findById,
                setRequiredRequestListInProgress,
                setRequiredRequestListCompleted,
                getRequiredRequestListProgressState,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const useKycRequest = (): KycRequestContext => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('useKycRequest must be used within a KycRequestProvider');
    }

    return context;
};
