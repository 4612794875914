/* eslint-disable no-restricted-imports */
import {
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Dialog as FuiDialog,
    DialogProps as FuiDialogProps,
    makeStyles,
    mergeClasses,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { cloneElement, PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { tokens } from '@zastrpay/theme';

import { Button } from './Button';

export type DialogAction = {
    text: string;
    style?: 'default' | 'link' | 'preferred';
    onClick?: () => void;
};

export type DialogProps = Pick<FuiDialogProps, 'open' | 'modalType'> & {
    /**
     * Title shown above the content. If `null` or `undefined` no title will be shown.
     */
    title?: string | null | React.ReactElement | false;
    /**
     * Whether a control to close the dialog is shown
     * @default true
     */
    dismissible?: boolean;
    /**
     * Alignment of the dialog relative to the page
     *
     * `center`: Horizontally and vertically centered
     *
     * `bottom`: Horizontally centered at the bottom of the page
     *
     * `bottom-full`: Full width at the bottom of the page
     *
     * @default center
     */
    align?: 'center' | 'bottom' | 'bottom-full';
    /**
     * Layouting of the content
     *
     * `padded`: Padding around the content
     *
     * `full`: No padding around the content, displayed from edge to edge (with safe insets)
     *
     * @default padded
     */
    content?: 'padded' | 'full';
    onOpenChange?: (open: boolean) => void;
    icon?: React.ReactElement | false;
    /**
     * Actions which will be displayed in the dialog. You can supply any ReactElement to handle custom rendering.
     */
    actions?: DialogAction[] | React.ReactElement | false;
    /**
     * Layouting of the action buttons
     *
     * `row`: Display action buttons in a single row
     *
     * `column`: Display action buttons in a column (one button per row)
     *
     * @default row
     */
    actionsDisplay?: 'row' | 'column';
};

export const Dialog: React.FC<PropsWithChildren<DialogProps>> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('components');

    const { dismissible = true, align = 'center', content = 'padded', actionsDisplay = 'row' } = props;

    const icon = useMemo(
        () => (props.icon ? cloneElement(props.icon, { className: mergeClasses(classes.icon, props.icon.props.className) }) : undefined),
        [props.icon, classes.icon],
    );

    return (
        <FuiDialog open={props.open} modalType={props.modalType} onOpenChange={(_, data) => props.onOpenChange?.(data.open)}>
            <DialogSurface
                className={mergeClasses(
                    classes.dialog,
                    align === 'bottom' && classes.dialogBottom,
                    align === 'bottom-full' && classes.dialogBottom,
                    align === 'bottom-full' && classes.dialogBottomFull,
                )}
            >
                <DialogBody className={classes.body}>
                    {(props.title || dismissible) && (
                        <DialogTitle className={classes.title} action={null}>
                            <div className={mergeClasses(classes.titleContent, dismissible && classes.titleContentDismissible)}>
                                {icon}
                                {props.title}
                            </div>
                            {dismissible && (
                                <DialogTrigger action="close">
                                    <Button
                                        className={classes.close}
                                        appearance="subtle"
                                        aria-label={t('dialog.close')}
                                        icon={<Dismiss24Regular />}
                                    />
                                </DialogTrigger>
                            )}
                        </DialogTitle>
                    )}
                    {props.children && (
                        <DialogContent className={mergeClasses(classes.content, content === 'full' && classes.contentFilled)}>
                            {props.children}
                        </DialogContent>
                    )}
                    {props.actions && (
                        <DialogActions className={mergeClasses(classes.actions, actionsDisplay === 'column' && classes.actionsColumn)}>
                            {Array.isArray(props.actions)
                                ? props.actions.map((action, index) => (
                                      <Button
                                          key={`dialog-action-${index}`}
                                          onClick={action.onClick}
                                          size="large"
                                          appearance={
                                              {
                                                  default: 'outline' as const,
                                                  link: 'transparent' as const,
                                                  preferred: 'primary' as const,
                                              }[action.style ?? 'default']
                                          }
                                      >
                                          {action.text}
                                      </Button>
                                  ))
                                : props.actions}
                        </DialogActions>
                    )}
                </DialogBody>
            </DialogSurface>
        </FuiDialog>
    );
};

const useStyles = makeStyles({
    icon: {
        fontSize: '3rem',
        color: tokens.colorNeutralForeground1,
        margin: tokens.spacingVerticalS,
    },
    dialog: {
        padding: '0',
        overflow: 'hidden',
        maxHeight: `calc(100% - ${tokens.customHeaderPadding} - ${tokens.customHeaderHeight} * 2)`,
        display: 'flex',
    },
    dialogBottom: {
        marginBottom: 0,
        paddingBottom: `max(${tokens.customFooterPadding}, ${tokens.spacingVerticalXXL})`,
        maxHeight: `calc(100% - ${tokens.customHeaderPadding} - ${tokens.customHeaderHeight})`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    dialogBottomFull: {
        height: `calc(100% - ${tokens.customHeaderPadding} - ${tokens.customHeaderHeight})`,
    },
    body: {
        flex: '1',
    },
    title: {
        boxSizing: 'border-box',
        fontSize: '18px',
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        width: '100%',
        padding: `${tokens.spacingVerticalM} ${tokens.spacingVerticalXL} ${tokens.spacingVerticalS}`,
    },
    titleContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    titleContentDismissible: {
        padding: '0 2rem',
    },
    close: {
        position: 'absolute',
        right: tokens.spacingHorizontalM,
        top: tokens.spacingVerticalMNudge,
        zIndex: 1000,
    },
    content: {
        position: 'relative',
        padding: `${tokens.spacingVerticalL} ${tokens.spacingHorizontalXXL} ${tokens.spacingVerticalS}`,
        width: 'auto',
        whiteSpace: 'pre-line',

        '& + .fui-DialogActions': {
            marginTop: tokens.spacingVerticalM,
        },
    },
    contentFilled: {
        padding: '0',
        paddingBottom: tokens.customFooterPadding,
    },
    actions: {
        gap: `${tokens.spacingVerticalM} ${tokens.spacingHorizontalS}`,
        padding: `0 ${tokens.spacingHorizontalXXL}`,
        // override fluentui styles for all screen sizes
        '@media screen and (min-width: 0px)': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
            gridColumnStart: 1,
        },
        '> *': {
            flex: '1 0 150px',
        },
    },
    actionsColumn: {
        '> *': {
            flex: 'auto',
            width: '100%',
        },
    },
});
